import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Layout from '../components/Layout';

const StyledNotFoundPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotFoundPage = () => {
  return (
    <Layout>
      <StyledNotFoundPage>
        <div className="not-found-inner">
          <h1>Sidan hittades inte</h1>
          <Link to="/">
            <Button>Gå till startsida</Button>
          </Link>
        </div>
      </StyledNotFoundPage>
    </Layout>
  );
};

export default NotFoundPage;
